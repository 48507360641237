
import { Component, Vue, Watch } from "vue-property-decorator";
import { Mixins } from "vue-mixin-decorator";
import GeneralMixin from "@/mixin";
import { Action, Getter, Mutation } from "vuex-class";
import {
  IInvoiceListRequest,
  IInvoice,
  IInvoiceCalculation
} from "@/types/invoice";
import { IPagination, ISelectItem } from "@/types";
import { ICustomerListRequest } from "@/types/customer";
import { IPaymentMethodListRequest } from "@/types/payment_method";

@Component({
  components: {}
})
export default class extends Mixins<GeneralMixin>(GeneralMixin) {
  // invoice
  @Action("invoice/invoiceOnlyGetList")
  public getInvoiceList!: (request: IInvoiceListRequest) => boolean;

  @Action("invoice/invoiceOnlyInvoiceCalculation")
  public getInvoiceCalculation!: (request: IInvoiceListRequest) => void;

  @Getter("invoice/list")
  public invoiceList!: IInvoice[];

  @Getter("invoice/pagination")
  public pagination!: IPagination<IInvoice[]>;

  @Getter("invoice/invoice_calculation")
  public invoiceCalculationResult!: IInvoiceCalculation;

  @Mutation("invoice/clear")
  public clearInvoice!: () => void;

  // customer
  @Action("customer/invoiceOnlyGetList")
  public getCustomerList!: (request: ICustomerListRequest) => boolean;

  @Getter("customer/selectItem")
  public customerList!: ISelectItem[];

  @Mutation("customer/clear")
  public clearCustomer!: () => void;

  // payment_method
  @Action("payment_method/invoiceOnlyGetList")
  public getPaymentMethodList!: (request: IPaymentMethodListRequest) => boolean;

  @Getter("payment_method/selectItem")
  public paymentMethodList!: ISelectItem[];

  @Mutation("payment_method/clear")
  public clearPaymentMethod!: () => void;

  // watch
  @Watch("$route.query")
  public async changeQuery() {
    this.queryToParams();
    await this.getInvoiceList(this.params);
  }

  // パラメータ定義
  public params: IInvoiceListRequest = {
    page: 1,
    search: "",
    payment_status: 0,
    search_span_start: "",
    search_span_end: "",
    sort_date_column: "",
    sort_direction: "",
    is_memo: false
  };

  //
  // variables
  //
  public exceed_payment_date = false;
  public invoice_calc_disp = false;
  public add_span_end = false;
  public span_end_show = true;
  public span_day_show = false;
  public menu = {
    start: false,
    end: false,
    day: false
  };

  public headers = [
    { text: "No", value: "invoice_no", sortable: false },
    {
      text: "請求状況",
      value: "payment_status",
      sortable: false
    },
    {
      text: "発行日",
      value: "invoice_date",
      sortable: false
    },
    {
      text: "入金予定日",
      value: "payment_date",
      sortable: false
    },
    {
      text: "入金認定日",
      value: "paid_date",
      sortable: false
    },
    { text: "顧客名", value: "customer_name", sortable: false },
    { text: "支払方法", value: "payment_method", sortable: false },
    {
      text: "税抜売上",
      value: "sales_excluding_tax",
      sortable: false,
      align: "end"
    },
    {
      text: "税込売上",
      value: "sales_including_tax",
      sortable: false,
      align: "end"
    },
    {
      text: "税額",
      value: "tax",
      sortable: false,
      align: "end"
    },
    { text: "内部メモ", value: "our_memo", sortable: false }
  ];

  public payment_statuses = [
    { value: 0, text: "全て表示" },
    { value: Vue.prototype.$paymentStatus.uncollected, text: "未回収" },
    { value: Vue.prototype.$paymentStatus.collected, text: "回収済" },
    {
      value: Vue.prototype.$paymentStatus.unnecessary_collect,
      text: "回収不要"
    }
  ];

  public sort_date_list = [
    { value: "", text: "全て表示" },
    { value: "invoice_date", text: "発行日" },
    { value: "payment_date", text: "入金予定日" },
    { value: "paid_date", text: "入金認定日" }
  ];

  public sort_items = [
    { value: "", text: "選択なし" },
    { value: "asc", text: "昇順" },
    { value: "desc", text: "降順" }
  ];

  //
  // methods
  //
  public async created() {
    this.clearInvoice();
    this.clearCustomer();
    this.clearPaymentMethod();
    this.queryToParams();

    await Promise.all([
      this.getInvoiceList(this.params),
      this.getCustomerList({ per_page: 0 }),
      this.getPaymentMethodList({ per_page: 0 })
    ]);
  }

  // URLのクエリからparamsをセットする
  private queryToParams() {
    const query = this.$route.query;
    this.params = {
      page: query.page ? Number(query.page) : 1,
      payment_status: query.payment_status ? Number(query.payment_status) : 0,
      customer: query.customer ? Number(query.customer) : 0,
      payment_method: query.payment_method ? Number(query.payment_method) : 0,
      search: query.search ? decodeURI(query.search as string) : "",
      sort_date_column: query.sort_date_column
        ? decodeURI(query.sort_date_column as string)
        : "",
      sort_direction: query.sort_direction
        ? decodeURI(query.sort_direction as string)
        : "",
      search_span_start: query.search_span_start
        ? decodeURI(query.search_span_start as string)
        : "",
      search_span_end: query.search_span_end
        ? decodeURI(query.search_span_end as string)
        : "",
      is_memo: query.is_memo ? Boolean(query.is_memo) : false
    };
  }

  // 1ページ目から検索
  // 主に検索条件が変更されたときに利用
  public async searchFirst() {
    this.invoice_calc_disp = false;
    this.params.page = 1;
    if (this.params.sort_date_column == "") {
      this.params.sort_direction = "";
    }
    await this.paramsToQuery();
  }

  // URLのクエリをセットする
  private paramsToQuery() {
    this.$router
      .push({
        path: this.$router.currentRoute.path,
        query: {
          page: this.params.page ? String(this.params.page) : "1",
          payment_status: this.params.payment_status
            ? String(this.params.payment_status)
            : "0",
          customer: this.params.customer ? String(this.params.customer) : "0",
          payment_method: this.params.payment_method
            ? String(this.params.payment_method)
            : "0",
          is_memo: this.params.is_memo ? String(this.params.is_memo) : "",
          search: this.params.search,
          search_span_start: this.params.search_span_start,
          search_span_end: this.params.search_span_end,
          sort_date_column: this.params.sort_date_column,
          sort_direction: this.params.sort_direction
        }
      })
      .catch(() => {});
  }

  // 開始日、終了日、日付指定のリセット
  public clearSearchStart() {
    this.params.search_span_start = "";
    this.searchFirst();
  }

  public clearSearchEnd() {
    this.params.search_span_end = "";
    this.searchFirst();
  }

  public clearSearchSpanDay() {
    this.params.search_span_start = "";
    this.params.search_span_end = "";
    this.searchFirst();
  }

  // 日付指定チェックボックスをクリックした際の挙動
  public span_toggle() {
    if (this.add_span_end == false) {
      this.params.search_span_end = "";
      this.params.search_span_start = "";
      this.span_end_show = true;
      this.span_day_show = false;
      this.searchFirst();
    } else {
      this.params.search_span_end = "";
      this.params.search_span_start = "";
      this.span_end_show = false;
      this.span_day_show = true;
      this.params.search_span_end = this.params.search_span_start;
      this.searchFirst();
    }
  }

  // 期間選択時のバリデーション
  public spanEndRule() {
    if (
      !this.params.search_span_end ||
      !this.params.search_span_start ||
      this.params.search_span_end >= this.params.search_span_start
    ) {
      return true;
    }

    return "正しい日付を入力してください";
  }

  //行をクリックした際の挙動
  public rowClick(invoice: IInvoice) {
    this.$router.push(`/invoice_only/invoice/${invoice.id}`);
  }

  // 未回収かつ入金予定日超過のデータを絞り込み
  public exceedPaymentDate() {
    if (!this.exceed_payment_date) {
      this.params.payment_status = 0;
      this.params.sort_date_column = "";
      this.params.search_span_end = "";
    }
    if (this.exceed_payment_date) {
      this.params.payment_status = Vue.prototype.$paymentStatus.uncollected;
      this.params.sort_date_column = "payment_date";
      this.params.search_span_end = this.toDay();
    }
    this.searchFirst();
  }

  // 請求書合計計算
  public async invoiceCalculation() {
    this.invoice_calc_disp = true;
    await this.getInvoiceCalculation(this.params);
  }
}
